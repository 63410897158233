.categories {
    padding: setting(spacing, element);

    // remove header margin-bottom
    margin-top: -$header-spacing;

    background-color: setting(color, grey);
    color: setting(color, white);

    font-size: setting(font-size, h1);
}

.categories__footer {
    font-size: setting(font-size, default);
}

.table {
    th {
        border-bottom: setting(border, header);
    }
}

// Add some specific styling for desktop.
// stylelint-disable max-nesting-depth
.table--details {
    td {
        padding: 0;
    }

    .table__title {
        font-weight: setting(font-weight, bold);

        @include media-query(desk) {
            text-align: right;
            padding-right: setting(spacing, element-narrow);
            width: 1%;
            vertical-align: top;
            white-space: nowrap;
        };
    }
}
// stylelint-enable max-nesting-depth

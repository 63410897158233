.attributes-list {
    dt,
    dd {
        padding: setting(spacing, inline);
        line-height: $b * 0.5;
    }

    dd {
        padding-top: setting(spacing, small);
    }
}

.attributes-list__item {
    @include media-query(desk) {
        text-align: left;
    }
}

@include media-query (desk) {
    .four-col {
        column-count: 4;
    }
}

/**
 * use inline block so content does not wraps to the next column.
 */
.four-col__col {
    display: inline-block; /*[1]*/
    width: 100%;
}

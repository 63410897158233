/*
    Disable some lint rules
    - declaration-no-important: we don't really want mutations on these utility
*/
/* stylelint-disable max-nesting-depth, declaration-no-important */

.u-uppercase {
    text-transform: uppercase;
}

.u-vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.u-text-center {
    text-align: center;
}

.u-text-center-responsive {
    text-align: center;

    @include media-query(desk) {
        text-align: inherit;
    }
}

.u-text-right {
    text-align: right;
}

.u-text-left {
    text-align: left;
}

.u-relative {
    position: relative;
}

.u-text-breakall {
    word-break: break-all;
}

.u-text-color-black {
    color: setting(color, black);
}

.u-text-brand {
    color: setting(color, text);
}

.u-sr-only {
    @include sr-only;
}

.u-truncate {
    @include truncate;
}

.u-flex {
    display: flex;
}

.u-flex-justify-center {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include media-query(desk) {
        flex-direction: row;
    }
}

.u-single-line {
    white-space: nowrap;
}

.u-flex-fill {
    flex: 1 1 auto;
}

.u-flex-needed {
    flex: 1 0 auto;
}

.u-flex-no-shrink {
    flex: 0 0 auto;
}

.u-justify-between {
    justify-content: space-between;
}

.u-align-end {
    align-items: flex-end;
}

.u-flex-align-center {
    align-items: center;
}

.u-align-self-center {
    align-self: center;
}

.is-hidden {
    display: none;
}

.u-float-right {
    float: right;
}

.u-float-right-desk {
    @include media-query(desk) {
        float: right;
    }
}

.u-margin-bottom {
    margin-bottom: setting(spacing, element);
}

.u-margin-bottom-big {
    margin-bottom: setting(spacing, element-big);
}

.u-margin-bottom-narrow {
    margin-bottom: setting(spacing, element-narrow);
}

.u-button-reset {
    padding: 0;
    margin: setting(spacing, inline);
    border: 0;
    border-radius: 50%;
    background-color: setting(color, gray);
}

.u-button-reset-room {
    border-radius: 0;
    padding: 0;
    margin: setting(spacing, inline);
    border: 0;
    background-color: setting(color, modal-close);
}

.u-float-right {
    float: right;
}

// hidden from sighted users but not from AT
.u-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
}

// set display to none for elements with the hidden attribute (this is for older browsers; < ie11)
[hidden] {
    display: none !important;
}

/* stylelint-enable max-nesting-depth, declaration-no-important */

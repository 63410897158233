.product {
    background-color: setting(color, grey);

    @include media-query(desk) {
        display: flex;
    }
}

.product__figure {
    flex: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        flex: 0 0 auto; /* Added so IE11 respects the max-width of the img. */
    }
}

.product__meta {
    flex: 50%;

    display: flex;

    padding: setting(spacing, element);
    color: setting(color, text);
}

.product__meta-figure {
    > img {
        flex: 0 0 auto; /* Added so IE11 respects the max-width of the img. */
    }
}

.product__media {
    display: flex;

    margin-bottom: setting(spacing, element);
}

.product__body {
    flex: 50%;

    padding: setting(spacing, element);


    color: setting(color, text-invert);
}

.product__add {
    display: flex;
    align-items: center;
}

.product__add-content {
    margin-left: setting(spacing, inline);
}

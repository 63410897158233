$card-figure-height: $b * 11.5;
$card-add-height: $b * 12.5;
$card-icon-size: $b * 4;

.card {
    display: block;
    text-decoration: none;

    color: setting(color, text-invert);
}

.card--project {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    margin-bottom: setting(spacing, inline);

    .card__content {
        flex: 1 0 auto;
    }
}

.card__figure {
    background-color: setting(color, white);
    text-align: center;
}

.card__figure--project {
    height: $card-figure-height;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.card__figure--aspect-ratio {
    position: relative;

    > img {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
    }
}

.card__content {
    padding: setting(spacing, inline);

    background-color: setting(color, grey);
}

.card__title {
    @include font(heading);
    @include inuit-font-size(setting(font-size, default));
}

.card__text {
    @include truncate;
    @include inuit-font-size(setting(font-size, smaller), 1);
}

.card__content--add {
    display: flex;
    justify-content: center;
    align-items: center;

    border: setting(border, project-add);
    background-color: setting(color, grey-lighter);
    color: setting(color, white);
    font-weight: setting(font-weight, bold);
    cursor: pointer;

    &:hover {
        border: setting(border, project-add-hover);
        color: setting(color, primary);
    }
}

.card__content--add-project {
    border: setting(border, project-add-board);

    &:hover {
        border: setting(border, project-add-hover-board);
    }
}

.card__content--add-custom {
    height: $card-add-height;
}

.card__content--icon {
    display: flex;
    justify-content: center;
    align-items: center;

    color: setting(color, black);
    border: setting(border, project-icon);
    font-size: setting(font-size, h2);

    &:hover {
        border: setting(border, project-icon-hover);
        color: setting(color, primary);
    }
}

.card__content--icon-product {
    height: $card-icon-size;
    width: $card-icon-size;
}

.card__content--meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card__footer a {
    @include truncate;
    width: 100%;
    display: block;
}

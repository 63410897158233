$select2-default-border-with: 1px;
// stylelint-disable declaration-no-important

// Needed to overwride default
.select2-container,
.select2-search__field {
    width: 100% !important;
}
// stylelint-enable declaration-no-important

.select2-container--classic .select2-selection--single .select2-selection__rendered {
    line-height: $field-height;
}

.select2-container .select2-selection--single {
    height: $field-height;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
    height: calc(100% - #{$select2-default-border-with * 2});
}

.select2-container {
    margin-bottom: setting(spacing, element-narrow);
}

.select2-container--classic .select2-selection--single {
    background-color: setting(color, white);
    background-image: none;
    border-radius: 0;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: setting(color, grey);
    color: setting(color, white);
    background-image: none;
    border-radius: 0;
}

// stylelint-disable selector-max-specificity, selector-max-compound-selectors
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
    border-color: setting(border-color, select);
}
// stylelint-enable selector-max-specificity, selector-max-compound-selectors

.select2-container--classic .select2-selection--single .select2-selection__clear {
    display: none;
}

.select2-container--classic .select2-selection--multiple {
    border-radius: 0;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: setting(color, input-focus);
    color: setting(color, white);
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
    background-color: setting(color, input-focus);
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: setting(color, white);
}

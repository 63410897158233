//
// #SETTINGS
//
// Default settings:
// https://github.com/inuitcss/settings.defaults/blob/master/_settings.defaults.scss
//
$inuit-base-font-size: 16px;
$inuit-base-line-height: 24px;

@import 'inuit-defaults/settings.defaults';
$b: ($inuit-base-line-height / $inuit-base-font-size) * 1rem;

$inuit-responsive-settings: true;
@import 'inuit-responsive-settings/settings.responsive';

/*
 * # Tools
 * Abstract functions for  getting or modifying data
 */
@import 'inuit-functions/tools.functions';
@import 'inuit-mixins/tools.mixins';
@import 'inuit-responsive-tools/tools.responsive';
@import 'inuit-tools-widths/tools.widths';

@import 'tools/functions';
@import 'tools/str-replace';
@import 'tools/svg';

/*
 * # Settings
 * All the project settings
 */
@import 'settings/private';
@import 'settings/colors';
@import 'settings/font';
@import 'settings/spacing';
@import 'settings/border';
@import 'settings/z-index';
@import 'settings/general';
@import 'settings/box-shadow';
@import 'settings/settings';

/*
 * # Mixins
 * Mixins that generate css that can be used in other files
 */
@import 'mixins/font';
@import 'mixins/element';
@import 'mixins/headings';
@import 'mixins/utilities';
@import 'mixins/aspect-ratio';
@import 'mixins/truncate';

/*
 * # Generic
 * Styling for all elements, like resets or normalizing
 */
@import 'normalize.css/normalize';
@import 'inuit-reset/generic.reset';
@import 'inuit-box-sizing/generic.box-sizing';

//
// #BASE
//
$inuit-base-background-color: setting(color, grey-lighter);
$inuit-base-text-color: setting(color, text);

@import 'inuit-page/base.page';
@import 'inuit-images/base.images';

@import 'generic/page';
@import 'generic/headings';
@import 'generic/links';
@import 'generic/list';
@import 'generic/table';
@import 'generic/button';

/*
 * # Elements
 * Base styling for some elements like tables, headings or images
 */
@import 'elements/flush';
@import 'elements/input';

/*
 * # Vendor
 * External packages
 */
@import 'jquery-confirm/dist/jquery-confirm.min';

$focus-border-color: setting(color, input-focus);
@import 'select2/src/scss/core';
@import 'select2/src/scss/theme/classic/layout';

/*
 * # Objects
 */

$inuit-enable-layout--center: true;
@import 'inuit-layout/objects.layout';

$inuit-enable-btn--small: true;
$inuit-enable-btn--full: true;
@import 'inuit-buttons/objects.buttons';

$inuit-enable-media--responsive: true;
@import 'inuit-media/objects.media';

@import 'objects/section';
@import 'objects/container';
@import 'objects/embed';
@import 'objects/content';
@import 'objects/actions';
@import 'objects/fields';
@import 'objects/cover';
@import 'objects/four-col';
@import 'objects/menu';
@import 'objects/grid';
@import 'objects/alerts';
@import 'objects/media';

.links {
    a + a {
        margin-left: setting(spacing, element-narrow);
    }
}

/*
 * # Components
 * All custom components like a menu, dashboard or buttons
 */
@import 'components/card';
@import 'components/icon';
@import 'components/page';
@import 'components/page-search';
@import 'components/page-control';
@import 'components/logo';
@import 'components/dropdown';
@import 'components/spinner-spacer';
@import 'components/vue-simple-spinner';
@import 'components/projects';
@import 'components/fields';
@import 'components/room';
@import 'components/button';
@import 'components/select2';
@import 'components/menu';
@import 'components/table';
@import 'components/product';
@import 'components/project-product';
@import 'components/logo-header';
@import 'components/modal';
@import 'components/section';
@import 'components/categories';
@import 'components/panel';
@import 'components/project-info';
@import 'components/attributes-list';
@import 'components/info-card';

/*
 * # Utilities
 * Very specific helper styling that are easy to use and have a simple purpose
 * like clearfix or hacks
 */
@import 'inuit-widths/trumps.widths';
@import 'inuit-print/trumps.print';

$inuit-widths-columns-responsive: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
@import 'inuit-responsive-widths/trumps.widths-responsive';

@import 'inuit-spacing/trumps.spacing';
@import 'inuit-clearfix/trumps.clearfix';
@import 'inuit-responsive-spacing/trumps.spacing-responsive';

@import 'utilities/utilities';
@import 'utilities/element';
@import 'utilities/position';
@import 'utilities/display';

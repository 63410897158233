// Postion fixed is necessary for the modal component.
// stylelint-disable declaration-property-value-blacklist

/**
 * Remove the page scrollbars, so the page can't be scrolled behind the modal.
 */
.has-modal {
    overflow: hidden;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: setting(z-index, modal);

    display: none;
    overflow: hidden;
    outline: 0;

    margin: setting(spacing, element) auto;

    transition: opacity 0.3s ease;

    @include media-query(desk) {
        max-width: setting(modal-max-width);
    }
}

.modal.is-open {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
}

.modal__dialog {
    margin: 0 setting(spacing, element-narrow);

    position: relative;
    width: auto;
    pointer-events: none;
}

.modal__content {
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;

    background-clip: padding-box;
    outline: 0;

    background-color: setting(color, grey-lighter);
    box-shadow: setting(box-shadow, header);

    transition: all 0.3s ease;
}

.modal__close {
    position: absolute;
    top: 0;
    right: 0;

    width: setting(spacing, element);
    height: setting(spacing, element);

    font-size: setting(spacing, element);
    line-height: 1;

    color: setting(color, text-invert);
    font-weight: setting(font-weight, bold);

    background-image: url('../images/close.svg');

    cursor: pointer;
}

.modal__project {
    .modal__close {
        position: absolute;
        top: 0;
        right: 0;

        width: setting(spacing, section);
        height: setting(spacing, section);

        font-size: setting(spacing, section);
        line-height: 1;

        color: setting(color, black);
        font-weight: setting(font-weight, bold);

        cursor: pointer;
        z-index: 1;
    }
}

.modal__project-custom {
    .u-button-reset {
        border-radius: 50%;
        background-color: setting(color, gray);
    }
}

// fix the times position
.modal__close-times {
    margin-top: -4px;
}

/**
 * Modal backdrop, not really a child of .modal but has it's place in this component.
 */
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: setting(z-index, modal-backdrop);

    background-color: setting(color, modal-backdrop);
}

/**
 * Animations
 */
.modal-enter .modal {
    opacity: 0;
}

.modal-leave-active .modal {
    opacity: 0;
}

.modal-enter .modal__content,
.modal-leave-active .modal__content {
    transform: scale(1.1);
}
// stylelint-enable declaration-property-value-blacklist

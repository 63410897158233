.page-control {
    display: flex;
}

.page-control__nav {
    border: 0;
    border-radius: 0;
    padding: setting(spacing, inline);

    background-color: setting(color, grey);
}

$header-spacing: $b;

.page-header {
    position: sticky;
    top: 0;
    z-index: setting(z-index, page-header);

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: setting(color, white);

    padding: setting(spacing, element-narrow) $inuit-base-spacing-unit;

    border-bottom: setting(border, header);
    box-shadow: setting(box-shadow, header);

    margin-bottom: $header-spacing;
}

.page-header-flush {
    margin-bottom: 0;
    box-shadow: none;
}

.page-header__control {
    flex: 1 1 0;
    margin-left: $inuit-base-spacing-unit;
    margin-right: $inuit-base-spacing-unit;
}

.alert {
    padding: setting(spacing, element-narrow);
    margin-bottom: setting(spacing, element);
    border: setting(border, alert);
}

.alert--success {
    background-color: setting(state, success-bg-color);
    border-color: setting(state, success-border-color);
    color: setting(state, success-color);
}

.alert--danger {
    background-color: setting(state, error-bg-color);
    border-color: setting(state, error-border-color);
    color: setting(state, error-color);
}

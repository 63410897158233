$icon-download-width: 89px;
$icon-download-height: 105px;

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.icon--project-board {
    width: $b / 2;
    height: $b / 2;
}

.icon--baseline {
    width: $b;
    height: $b;
}

.icon--medium {
    width: $b * 2;
    height: $b * 2;

    @include media-query(desk) {
        margin-right: $b;
    }
}

.icon--downloads {
    width: $icon-download-width;
    height: $icon-download-height;
}

.icon--medium-no-margin {
    margin-right: 0;
}

.icon--big {
    width: $b * 4;
    height: $b * 4;
}

.icon--invert {
    fill: setting(color, text-invert);
}

.icon--context-after {
    margin-left: setting(spacing, inline);
}

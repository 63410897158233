$header-height: $b * 14.5;
$header-circle-size: $b * 9.5;
$header-circle-logo-position-top: $b * 8;

.logo-header {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    width: 100%;
    height: $header-height;
    position: relative;
    margin-bottom: calc(#{setting(spacing, element)} + (#{$header-circle-size} / 2.5));

    .logo-header__logo {
        background-color: setting(color, white);
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
        width: $header-circle-size;
        height: $header-circle-size;
        border-radius: 50%;
        display: block;
        position: absolute;
        top: $header-circle-logo-position-top;

        // Calculate logo center position: 50%.
        left: calc(50% - (#{$header-circle-size} / 2));

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.logo-header--information-page {
    margin-top: -$header-spacing;
}

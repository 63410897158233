html {
    @include font;

    overflow-y: scroll;
    overflow-x: hidden;

    min-height: 100%;

    text-size-adjust: 100%;

    height: 100%;

    color: setting(color, grey);
}

p {
    margin-bottom: setting(spacing, element-narrow);
}

.p--flush {
    margin-bottom: 0;
}

.small {
    @include inuit-font-size(setting(font-size, smaller));
}

input {
    border-radius: 0;
}

.project-product {
    color: setting(color, text);
}

.project-product__meta {
    padding: setting(spacing, element);

    @include media-query(desk) {
        padding: 0 setting(spacing, element);
    }
}

.project-product__body {
    padding: setting(spacing, element);

    @include media-query(desk) {
        padding: 0 setting(spacing, element);
    }
}

.dropdown {
    position: relative;
    z-index: 1;
}

.dropdown__trigger {
    display: block;
}

.dropdown__menu {
    display: none;

    position: absolute;
    top: 100%;
    right: 0;

    float: left;

    padding: setting(spacing, element);

    background-color: setting(color, grey);
    box-shadow: setting(box-shadow, header);

    color: setting(color, text-invert);
}

.dropdown.is-active .dropdown__menu {
    display: block;
}

.dropdown__menu--header {
    min-width: 50vw;
}

.btn {
    word-break: break-word;
}

.btn--primary {
    background-color: setting(color, primary);
    border-color: setting(color, primary);
    color: setting(color, white);

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        outline: 0;
    }
}

.btn--default {
    background-color: setting(color, grey);
    border-color: setting(color, grey);
    color: setting(color, white);
}

.btn--secondary {
    background-color: setting(color, grey-darker);
    border-color: setting(color, grey-darker);
    color: setting(color, white);
}

.btn--ghost {
    background-color: transparent;
    border: setting(border, default);
    color: setting(color, grey);

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        background-color: setting(color, primary);
        border-color: setting(color, primary);
        color: setting(color, grey-darker);
    }
}

.btn--link {
    padding: 0;
    border: 0;
    background-color: transparent;

    color: setting(color, link);
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: setting(color, link-highlight);
        text-decoration: underline;
    }
}

.btn--link-invert {
    padding: 0;
    border: 0;
    background-color: transparent;

    color: setting(color, white);
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: setting(color, link-highlight);
        text-decoration: underline;
    }
}

.btn--medium {
    width: percentage(7/10);
}

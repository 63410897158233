.info-card {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;

    padding: setting(spacing, section-big) ($gutter-split * 4);
    background-color: setting(color, white);
    color: setting(color, grey-darker);
    box-shadow: setting(box-shadow, info);
}

.info-card--block {
    display: block;
    margin-bottom: setting(spacing, element-narrow);
}

.info-card__image {
    flex: 0 0 auto;

    margin-bottom: setting(spacing, element-narrow);
}

.info-card__content {
    text-align: center;
}

.info-card--no-shadow {
    box-shadow: none;
}

.info-card__button {
    margin-top: auto;
}

.projects {
    display: flex;
    flex-direction: column;

    @include media-query(desk) {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.projects__item {
    display: flex;
    flex-direction: column;
    margin-bottom: setting(spacing, element-narrow);

    @include media-query(desk) {
        width: calc(#{percentage(1/6)} - #{setting(spacing, element)});
        margin-right: setting(spacing, element-narrow);
        margin-left: setting(spacing, element-narrow);
    }
}

$gutter-split: $inuit-base-spacing-unit / 2;

.grid {
    margin-left: $gutter-split;
    margin-right: $gutter-split;
}

.grid-info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    margin-left: $gutter-split;
    margin-right: $gutter-split;

    @include media-query(lap-and-up) {
        flex-direction: row;
        justify-content: center;

        margin-left: -$gutter-split;
        margin-right: -$gutter-split;

        padding-right: $gutter-split * 4;
        padding-left: $gutter-split * 4;
    }
}

.grid-info-title {
    padding: 0 ($gutter-split * 2);
}

.grid-info-page {
    margin-left: 0;
    margin-right: 0;

    padding-left: 0;
    padding-right: 0;
    margin-bottom: setting(spacing, section);

    @include media-query(desk) {
        margin-bottom: $gutter-split * 9.5;
    }
}

.grid-downloads {
    padding-left: $gutter-split * 2;
    padding-right: $gutter-split * 2;
    margin-left: 0;
    text-align: center;

    @include media-query(lap-and-up) {
        padding-left: $gutter-split * 10;
        padding-right: $gutter-split * 10;
    }
}

.grid__sizer,
.grid__item {
    width: percentage(1/2);

    @include media-query(lap-and-up) {
        width: percentage(1/3);
    }

    @include media-query(desk) {
        width: percentage(1/6);
    }
}

.grid__item {
    padding-left: $gutter-split;
    padding-right: $gutter-split;

    float: left;
}

.grid__item-downloads {
    display: inline-block;
    vertical-align: top;
    color: setting(color, text);
    margin-bottom: setting(spacing, element);
    padding-left: $gutter-split * 2;
    padding-right: $gutter-split * 2;
}

.grid__item-downloads-link {
    color: setting(color, grey-darker);
    display: block;
}

.grid__item-info {
    display: flex;
    margin-bottom: setting(spacing, section);
    padding: 0 $gutter-split;
    width: 100%;

    @include media-query(desk) {
        width: calc(#{percentage(1/3)} - (#{$gutter-split} * 6));
        padding: 0;
        margin-bottom: 0;
    }

    // Custom media-query for iPad(pro)
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
        width: calc(#{percentage(1/3)} - (#{$gutter-split} * 1.5));
    }
}

.grid__item-info:not(:last-child) {
    @include media-query(desk) {
        padding-right: $gutter-split * 6;
        width: percentage(1/3);
    }

    // Custom media-query for iPad(pro)
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
        padding-right: $gutter-split * 1.5;
    }
}

.grid__item-info-page {
    display: flex;
    margin-bottom: setting(spacing, section);
    padding-left: $gutter-split * 2;
    padding-right: $gutter-split * 2;
    width: 100%;

    @include media-query(desk) {
        width: percentage(1/3);
        margin-bottom: 0;
        padding-left: $gutter-split * 6;
        padding-right: $gutter-split * 6;
    }

    // Custom media-query for iPad(pro)
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
        padding-left: $gutter-split * 1.5;
        padding-right: $gutter-split * 1.5;
    }
}

.grid__item-add {
    width: 100%;
}

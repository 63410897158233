.fields {
    border: 0;
    margin-bottom: setting(spacing, element-narrow);
}

.field__label--sr-only {
    @include sr-only;
}

.field__input,
.field__message {
    display: block;
    width: 100%;
    margin-bottom: setting(spacing, element-narrow);

    &--flush {
        margin-bottom: 0;
    }
}

.field__input {
    padding-left: setting(spacing, element-narrow);
    outline: 0;
}

/* Varations */
.field--inline {
    .field__label,
    .field__input {
        vertical-align: middle;
        display: inline-block;
        width: auto;
        margin-bottom: setting(spacing, element-narrow);
    }
}

.field--row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .field__label {
        margin-bottom: setting(spacing, element-narrow);
    }
}

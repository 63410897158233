$field-height: $b * 2;
$field-padding: ($field-height - $b) / 2;
$field-border: 1px;
$select-icon-top: 16px;

.field__input {
    border: setting(border, input);
    padding: calc(#{$field-padding} - #{$field-border});

    &:focus {
        border-color: setting(color, input-focus);
    }
}

.field__input:not(.field__input--textarea) {
    height: $field-height;
}

.field__input--textarea {
    resize: vertical;
    min-height: $field-height;
}

.field__input--no-padding {
    padding-left: 0;
}

.is-required .field__label::after {
    content: '*';
    color: setting(color, primary);
    padding-left: setting(spacing, inline);
    font-weight: setting(font-weight, bold);
}

.field__label {
    font-weight: setting(font-weight, bold);
}

.field--radio,
.field--check {
    .field__input {
        display: inline-block;
        width: auto;
        height: auto;
        margin-right: setting(spacing, inline);
    }
}

.field--select-button {
    display: flex;

    .field__input {
        margin-right: setting(spacing, inline);
    }

    .field__button {
        flex: 1 0 auto;
    }

    .field__button--flex-none {
        flex: none;
    }
}

.field__input--select {
    background-color: setting(color, white);
    appearance: none;
    border-radius: 0;
    width: 100%;

    &::-ms-expand {
        display: none;
    }
}

.field__input--select-icon {
    position: relative;
    height: $field-height;
    margin-bottom: setting(spacing, element-big);
}

.field__input--select-icon-room {
    margin-right: setting(spacing, inline);
    flex: 1 0 auto;
}

.field__input--select-icon::after {
    content: url('../images/down-arrow.svg');
    pointer-events: none;
    width: $b / 1.5;
    height: auto;

    position: absolute;
    right: $b / 2;
    top: $select-icon-top;
}

.field__input--number {
    font-variant-numeric: tabular-nums;
    width: calc(10ch + #{$field-padding * 2});
}

.has-error .field__message,
.has-error .help-block,
.help-block {
    color: setting(state, error-color);
}

.has-error .field__input,
.has-error .select2-container--classic .select2-selection--single,
.has-error .select2-container--classic .select2-selection--multiple {
    background-color: lighten(setting(state, error-color), 40%);
    border-color: setting(state, error-color);
}

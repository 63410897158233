@mixin font($type: default) {
    @if $type == default {

        font-family: 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;

    } @else if $type == heading {

        font-weight: setting(font-weight, bold);

    } @else {

        @warn 'D’oh! ‘#{$type}’ is not a valid value for the font stack.';

    }
}

.alpha {
    @include alpha;
}

.beta {
    @include beta;
}

.gamma {
    @include gamma;
}

.table {
    width: 100%;
    margin-bottom: setting(spacing, element);

    th {
        text-align: left;
    }

    td,
    th {
        padding: setting(spacing, element-narrow);
    }
}

.table--responsive {
    @include media-query(portable) {
        display: block;
        width: 100%;
        overflow-x: auto;
    }
}

.td-single-line {
    width: 1%;
}

.table--clean {
    th,
    td {
        padding-left: 0;
        padding-right: 0;
    }
}

@include media-query(portable) {
    .table--dl {
        tr {
            display: flex;
            flex-direction: column;

            margin-bottom: setting(spacing, element);
        }

        td {
            padding: 0;
        }
    }
}

a {
    color: setting(color, link);
    word-break: break-all;

    &:hover,
    &:focus,
    &:active {
        color: setting(color, link-highlight);
    }

    > object,
    > svg {
        pointer-events: none;
    }
}

.link--light {
    color: setting(color, grey);
}

.link--delete {
    color: setting(color, link-delete);
}

.link--invert {
    color: setting(color, white);
}

.link--no-underline {
    text-decoration: none;
}

.link--underline {
    text-decoration: underline;
}
